<template>
  <div class="d-flex flex-column">
    <v-row justify="center">
      <v-col cols="10" class="d-flex">
        <v-input
          prepend-icon="location_city"
          background-color="blue lighten-5"
          height="40"
          hint="Your current address"
        >
          <vue-google-autocomplete
            id="map"
            ref="location_set"
            class="location_city"
            placeholder="Start typing your address"
            v-on:placechanged="_getAddressData"
          ></vue-google-autocomplete>
        </v-input>
      </v-col>
      <v-col cols="1" v-if="showLocationIcon" class="d-flex">
        <v-btn
          class="location_icon right"
          small
          text
          @click.prevent="getAddressFromLocation"
        >
          <v-icon>my_location</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="formatted_address">
      <v-col cols="12" class="d-flex flex-column pl-8 pr-2">
        <v-form
          @submit.prevent="onUpdateAddress"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row justify="center" class="d-flex w-100">
            <v-col cols="12" sm="7" v-if="!hideName">
              <v-text-field
                name="name"
                dense
                color="takein"
                label="Name of location (i.e. Primary)"
                v-model="name"
              ></v-text-field>
            </v-col>

            <v-col cols="10" sm="3">
              <v-select
                dense
                required
                :items="availableTypes"
                v-model="type"
                label="Type"
              ></v-select>
            </v-col>

            <v-col cols="2" sm="2">
              <v-tooltip bottom maxWidth="240px">
                <template v-slot:activator="{ on }">
                  <v-icon color="takein" v-on="on">info</v-icon>
                </template>
                <div>
                  <h6>{{ $t("Common.address_help_pickup") }}</h6>
                  <h6>{{ $t("Common.address_help_billing") }}</h6>
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row justify="center" class="d-flex w-100">
            <v-col cols="12" sm="6" md="7">
              <v-text-field
                name="address1"
                color="takein"
                dense
                label="Address1"
                v-model="address1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                name="address2"
                color="takein"
                dense
                label="Address2"
                v-model="address2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center" class="d-flex w-100">
            <v-col cols="12" sm="6">
              <v-text-field
                name="city"
                label="City"
                color="takein"
                dense
                :rules="[(v) => !!v || 'City is required']"
                required
                v-model="city"
              ></v-text-field>
            </v-col>

            <v-col cols="6" sm="2">
              <v-autocomplete
                dense
                color="takein"
                v-bind:items="states"
                v-model="state"
                label="State"
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" sm="4">
              <v-text-field
                dense
                color="takein"
                name="zipcode"
                label="ZIP code"
                v-model="zipcode"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" class="d-flex w-100">
            <v-col cols="12" sm="12">
              <v-text-field
                color="takein"
                dense
                name="address_instruction"
                hint="(i.e. Park in the back of the building)"
                label="Address Instruction"
                v-model="address_instruction"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mb-4 ml-3">
              <v-btn
                type="submit"
                color="takein"
                dark
                :ripple="false"
                depressed
                class="mr-3"
                >Save</v-btn
              >
              <v-btn text depressed :ripple="false" @click="onCancel"
                >Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12 px-8">
        <v-card
          v-if="location.lat"
          color="grey lighten-1"
          class="mb-5 map demo"
          height="300px"
        >
          <google-map :center="location" class="map" :zoom="13" ref="map">
            <google-marker
              :position="location"
              :clickable="true"
              :draggable="true"
            ></google-marker>
          </google-map>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import * as VueGoogleMaps from "vue2-google-maps";

import { states } from "@/data/static";
import { MapMixin } from "../../../mixins/mapMixin";

export default {
  props: {
    isChef: { Type: Boolean },
    profile: { Type: Object },
    hideName: Boolean,
    showLocationIcon: Boolean,
    addressType: String,
    selectedIndex: Number,
    addresses: {},
    mode: String,
    index_no: Number,
  },
  mixins: [MapMixin],

  components: {
    VueGoogleAutocomplete,
    "google-map": VueGoogleMaps.Map,
    "google-marker": VueGoogleMaps.Marker,
  },
  watch: {
    selectedIndex: function(newval, oldval) {
      if (newval !== oldval) {
        this.selectedAddress = this.addresses[newval];
        this.loadAddressInfo();
        return newval;
      }
    },
  },

  data() {
    return {
      valid: true,
      name: null,
      city: null,
      latitude: null,
      address1: null,
      address2: null,
      zipcode: null,
      state: null,
      types: ["pickup", "primary", "billing", "current", "other"],
      availableTypes: ["pickup", "primary", "billing", "current", "other"],
      type: "pickup",
      location: {},
      address_instruction: null,
      formatted_address: null,
      states: states,
      selectedAddress: {},
      //editable: false
    };
  },
  methods: {
    onCancel() {
      this.$emit("closeAddress");
    },
    getAvailableTypes() {
      let new_types = Array.from(this.types);
      if (this.addresses) {
        this.addresses.forEach((address) => {
          new_types = new_types.filter((type) => type !== address.type);
        });
        if (this.mode === "edit") {
          new_types.push(this.addressType);
        }
      }
      return new_types;
    },
    onUpdateAddress() {
      if (this.$refs.form.validate()) {
        console.log("Valid form");
      } else {
        return;
      }

      if (!this.isChef) {
        this.type = "home";
        // this.addressType = 'home'
      } else if (this.mode === "edit" && this.addressType) {
        this.type = this.addressType;
      } else {
        console.warn("addressType is missing");
      }

      let address = {
        no: this.index_no,
        name: this.name,
        address1: this.address1,
        address2: this.address2,
        city: this.city,
        state: this.state,
        latitude: this.latitude,
        longitude: this.longitude,
        zipcode: this.zipcode,
        type: this.type,
        address_instruction: this.address_instruction,
        formatted_address: this.formatted_address,
      };
      if (this.latitude instanceof Function) {
        address.latitude = this.latitude();
        this.location.lat = this.latitude();
      }
      if (this.longitude instanceof Function) {
        address.longitude = this.longitude();
        this.location.lng = this.longitude();
      }
      console.log(address, "address added");
      this.$emit("saveAddress", address, this.mode);
    },

    _getAddressData: function(addressData, placeResultData) {
      this.location = {};
      console.log("addressData", addressData);
      console.log("placeResultData", placeResultData.formatted_address);

      this.formatted_address = placeResultData
        ? placeResultData.formatted_address
        : null;
      this.address1 =
        (addressData.street_number || "") + " " + (addressData.route || "");

      this.city = addressData.locality || "";
      this.state = addressData.administrative_area_level_1 || "";
      this.zipcode = addressData.postal_code || "";
      this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;

      this.location.lat = addressData.latitude;

      this.location.lng = addressData.longitude;
    },
    getAddressFromLocation() {
      // var options = {
      //   enableHighAccuracy: false,
      //   timeout: 10000,
      //   maximumAge: 600000
      // }
      // console.log('Getting GPS location and saving to storage')

      // return this.GeoCoder(options).then(position => {
      //   console.log('qqqqqqqqqqq', position)
      // })
      this.location = {};

      this.reverseGeocode().then((addressData) => {
        if (addressData) {
          this.city = addressData.city || "";
          this.address1 = addressData.address1;
          this.state = addressData.state || "";
          this.zipcode = addressData.zipcode || "";
          this.latitude = addressData.location.latitude || "";
          this.longitude = addressData.location.longitude || "";
          this.location.lat = addressData.location.latitude;
          this.location.lng = addressData.location.longitude;
          console.log("addressData", addressData, this.city);
        } else {
          console.error("Can not get the address for current location");
        }
      });
    },
    loadAddressInfo() {
      if (this.mode === "edit") {
        this.name = this.selectedAddress.name;
        this.address1 = this.selectedAddress.address1;
        this.address2 = this.selectedAddress.address2;
        this.city = this.selectedAddress.city;
        this.state = this.selectedAddress.state;
        this.latitude = this.selectedAddress.latitude;
        this.longitude = this.selectedAddress.longitude;
        this.zipcode = this.selectedAddress.zipcode;
        this.type = this.selectedAddress.type;
        this.address_instruction = this.selectedAddress.address_instruction;
        this.formatted_address = this.selectedAddress.formatted_address;
      }
      
      if (!this.name) {
        this.name = this.profile ? this.profile.businessName : null;
      }
    },

    // setAddressFromLocation(address){
    //   let cp = this.getAddressData(address)
    //   console.log('1111111', this.getAddressData(cp,address))
    // }
  },
  mounted() {
    this.availableTypes = this.getAvailableTypes();
    this.type =
      this.mode === "edit" ? this.addressType : this.availableTypes[0];
    this.selectedAddress = this.addresses[this.selectedIndex];
    this.loadAddressInfo();
  },
};
</script>
<style scoped>
.location_city {
  padding: 1em;
  width: 80%;
}
.demo {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.map {
  flex: 100% 1 1;
}
.location_icon {
  position: relative;
}
</style>
